import { IncomingMessage, ServerResponse } from 'http';

import { ssrRedirect } from './ssrRedirect';

export const redirectToCorrectUrl = (
  response: ServerResponse<IncomingMessage>,
  url: string,
  correctUrl: string
) => {
  if (!response) return;
  if (!correctUrl) return;
  if (url.split(/[?#]/)[0].toLowerCase() !== correctUrl.toLowerCase()) {
    ssrRedirect(response, correctUrl);
  }
};
