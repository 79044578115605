import { useRouter } from 'next/router';
import { API_URL } from 'src/lib/constants/env.constants';

const usePageCanonical = () => {
  const router = useRouter();
  const { query } = router;

  const getDefaultCanonical = (path: string) => {
    return `${API_URL}${path}`;
  };

  const getGroupCanonical = () => {
    const { categoryId, groupId, firstParam, group } = query;
    return `${API_URL}/${firstParam}/${group}/grupa/${categoryId}/${groupId}/1`;
  };
  const getSearchPageCanonical = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, firstParam, group, ...rest } = query as {
      [key: string]: string;
    };
    const searchParams = new URLSearchParams(rest).toString();
    return `${API_URL}/pretraga?${searchParams}`;
  };
  const getAllUsersAdsPageCanonical = () => {
    const { firstParam, userId } = query;
    return `${API_URL}/${firstParam}/svi-oglasi/${userId}/1`;
  };

  const getAdItemCanonical = () => {
    const { firstParam, group, ad, adId } = query;
    return `${API_URL}/${firstParam}/${group}/${ad}/oglas/${adId}`;
  };

  const getReviewCanonical = () => {
    const { firstParam, userId, page, rate } = query;
    const queryString = rate !== 'positive' ? `?rate=${rate}` : '';
    return `${API_URL}/${firstParam}/ocene/${userId}/${page}${queryString}`;
  };

  return {
    getGroupCanonical,
    getSearchPageCanonical,
    getAllUsersAdsPageCanonical,
    getDefaultCanonical,
    getAdItemCanonical,
    getReviewCanonical,
  } as const;
};

export default usePageCanonical;
